import useFetchData from "../../../hooks/useFetchData";
import {
  Accordion,
  AccordionDetails,
  Grid,
  Typography,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import React from "react";
import CurrentConditionsCard from "../../../components/CurrentConditionsCard";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Panel from "../../../components/panels/Panel";
import Loader from "../../../components/Loader";

const CurrentConditions = ({ currentConditionsEndpoint }) => {
  const [data, isLoading] = useFetchData(currentConditionsEndpoint, []);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="time-series"
            id="time-series"
          >
            <Typography variant="h4" ml={2}>
              Current Conditions
            </Typography>
          </AccordionSummary>
          <Panel>
            <AccordionDetails>
              {isLoading ? (
                <Loader />
              ) : !data?.length ? (
                <Typography>No data available</Typography>
              ) : (
                <Grid container>
                  {data?.map((item) => (
                    <CurrentConditionsCard
                      key={item.location_ndx}
                      data={item}
                    />
                  ))}
                </Grid>
              )}
            </AccordionDetails>
          </Panel>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default CurrentConditions;

import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

const RedirectHandler = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    if (isLoading || !isAuthenticated) {
      return;
    }

    const shouldRedirect = localStorage.getItem("postLoginRedirect");

    if (shouldRedirect) {
      localStorage.removeItem("postLoginRedirect");
      const userAppMetadataFixedStationsDisplayId =
        user[`${process.env.REACT_APP_AUDIENCE}/fixed_stations_display_id`];
      if (userAppMetadataFixedStationsDisplayId) {
        history.push(
          `/data-access/system-graphs/fixed-stations/${userAppMetadataFixedStationsDisplayId}`
        );
      } else {
        history.push("/dashboard");
      }
    }
  }, [user, isAuthenticated, isLoading, history]);

  return null;
};

export default RedirectHandler;

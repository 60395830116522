import {
  Box,
  FormControl as MuiFormControl,
  Select,
  MenuItem,
  TextField,
  Typography,
  Button,
  withWidth,
  isWidthDown,
} from "@material-ui/core";
import styled from "styled-components/macro";

const FormControl = styled(MuiFormControl)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;

const BufferInputRow = ({ bufferName, onChange, values, width }) => {
  return (
    <Box my={isWidthDown("xs", width) ? 1 : 4}>
      <TextField
        style={{ width: "calc(100% - 90px)" }}
        id={`${bufferName}-bufferAmount`}
        name="bufferAmount"
        size="small"
        onChange={(event) => onChange(event, bufferName)}
        value={values.bufferAmount}
        variant="outlined"
      />

      <FormControl size="small" variant="outlined">
        <Select
          labelId={`${bufferName}-units-label`}
          id={`${bufferName}-units`}
          name="units"
          value={values.units}
          onChange={(event) => onChange(event, bufferName)}
        >
          <MenuItem value="miles">Miles</MenuItem>
          <MenuItem value="feet">Feet</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const SearchRadiusControl = ({
  bufferValues,
  onBufferValuesChange,
  onClearBuffers,
  onResetBuffers,
  width,
}) => {
  const handleClearBuffers = (event) => {
    event.preventDefault();
    onClearBuffers();
  };

  return (
    <Box p={1}>
      {/*<Typography variant="body1">*/}
      {/*  This control can be used to create radius rings to easily identify if*/}
      {/*  features fall within a specified area. To use the tool, click anywhere*/}
      {/*  on the map.*/}
      {/*</Typography>*/}
      <Box my={1} width="100%">
        <Typography variant="subtitle1">Buffers</Typography>
        <form onSubmit={handleClearBuffers}>
          <Box>
            <BufferInputRow
              width={width}
              bufferName="buffer1"
              onChange={onBufferValuesChange}
              values={bufferValues.buffer1}
            />
            <BufferInputRow
              width={width}
              bufferName="buffer2"
              onChange={onBufferValuesChange}
              values={bufferValues.buffer2}
            />
            <BufferInputRow
              width={width}
              bufferName="buffer3"
              onChange={onBufferValuesChange}
              values={bufferValues.buffer3}
            />
            <BufferInputRow
              width={width}
              bufferName="buffer4"
              onChange={onBufferValuesChange}
              values={bufferValues.buffer4}
            />
          </Box>
          <Box display="flex" gridColumnGap={8}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size={isWidthDown("xs", width) ? "small" : "medium"}
              style={{
                padding: isWidthDown("xs", width) ? "4px 5px" : "6px 16px",
              }}
            >
              Clear Buffers
            </Button>
            <Button
              fullWidth
              onClick={onResetBuffers}
              type="reset"
              variant="contained"
              size={isWidthDown("xs", width) ? "small" : "medium"}
              style={{
                padding: isWidthDown("xs", width) ? "4px 5px" : "6px 16px",
              }}
            >
              Reset Form
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default withWidth()(SearchRadiusControl);

export const BASEMAP_STYLES = [
  {
    style: "satellite-streets-v11",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    style: "outdoors-v11",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    style: "streets-v11",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
  },
  {
    style: "light-v10",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  {
    style: "dark-v10",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v10",
  },
];

export const DEFAULT_MAP_CENTER = [-104.255932, 40.339271];

export const INIT_MAP_CONFIG = {
  style: BASEMAP_STYLES[0].url,
  center: DEFAULT_MAP_CENTER,
  zoom: 10,
  preserveDrawingBuffer: true,
};

export const WELLS_LAYER_ID = "riverside-locations-circle";
export const WELLS_LABELS_LAYER_ID = "riverside-locations-symbol";
export const INIT_FILTER_VALUES = {
  reportingStatus: {
    label: "Reporting Status",
    name: "reportingStatus",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "reporting_status_desc",
    options: [],
    type: "multi-select",
    value: [],
  },
  locationTypes: {
    label: "Location Types",
    name: "locationTypes",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "locationtype",
    options: [],
    type: "multi-select",
    value: [],
  },
  hasFlowGraph: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "has_flow_graph",
    type: "boolean",
    value: false,
    label: "More Filters",
  },
  hasStageGraph: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "has_stage_graph",
    type: "boolean",
    value: false,
    label: "More Filters",
  },
  hasWaterlevelsGraph: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "has_waterlevel_graph",
    type: "boolean",
    value: false,
    label: "More Filters",
  },
};

const layerId = "riverside-locations-circle";
export const INIT_STYLE_VALUES = {
  reportingStatus: {
    id: "reportingStatus",
    layerId,
    layerFieldName: "reporting_status_desc",
    name: "Reporting Status",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "reporting_status_desc"],
        "No Values Available",
        "#fff",
        "#fff",
      ],
    },
  },
  locationTypes: {
    id: "locationTypes",
    layerId,
    layerFieldName: "locationtype",
    name: "Location Types",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "locationtype"],
        "No Values Available",
        "#fff",
        "#fff",
      ],
    },
  },
};

import React, { useState } from "react";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useApp } from "../../../AppProvider";
import MaterialTable from "material-table";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CopyIcon from "@material-ui/icons/FileCopy";
import {
  Accordion,
  AccordionDetails,
  Divider as MuiDivider,
  Grid as MuiGrid,
  TextField,
  Button,
  AccordionSummary,
  Typography as MuiTypography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";

import Panel from "../../../components/panels/Panel";
import Loader from "../../../components/Loader";
import {
  copyToClipboard,
  dateFormatter,
  getLookupForDataSortedByField,
} from "../../../utils";
import useFetchData from "../../../hooks/useFetchData";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

function AlertsManagement() {
  const { doToast } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const [selectedRow, setSelectedRow] = useState(null);
  const [showStale, setShowStale] = useState(false);

  const handleShowStale = () => {
    setShowStale((prevState) => {
      return !prevState;
    });
  };

  const defaultRowValues = {
    alert_request_ndx: null,
    enabled: null,
    alert_type_ndx: null,
    alert_type: null,
    location_ndx: null,
    location: null,
    parameter_ndx: null,
    parameter: null,
    trigger_value: null,
    reset_hours: null,
    merged_address_ndx: null,
    send_to_details: null,
    send_to: null,
    custom_subject: null,
    custom_loc_name: null,
    notes: null,
  };

  const [AlertTypes] = useFetchData("alerts-list-alert-types", [], true);
  const [Locations] = useFetchData("alerts-list-locations", [], true);
  const [Parameters] = useFetchData("alerts-list-parameters", [], true);
  const [SendTo] = useFetchData("alerts-list-send-to", [], true);

  const handleUpdateSelectedRow = (name, value) => {
    if (name === "alert_type_ndx") {
      setSelectedRow((prevState) => {
        let newFilterValues = { ...prevState };
        newFilterValues[name] = value;
        newFilterValues["alert_type"] = AlertTypes.find(
          (alertType) => alertType.alert_type_ndx === value
        ).alert_type;
        return newFilterValues;
      });
    } else if (name === "location_ndx") {
      setSelectedRow((prevState) => {
        let newFilterValues = { ...prevState };
        newFilterValues[name] = value;
        newFilterValues["location"] = Locations.find(
          (location) => location.location_ndx === value
        ).location;
        return newFilterValues;
      });
    } else if (name === "parameter_ndx") {
      setSelectedRow((prevState) => {
        let newFilterValues = { ...prevState };
        newFilterValues[name] = value;
        newFilterValues["parameter"] =
          Parameters.find((parameter) => parameter.parameter_ndx === value)
            ?.parameter ?? null;
        return newFilterValues;
      });
    } else if (name === "merged_address_ndx") {
      setSelectedRow((prevState) => {
        let newFilterValues = { ...prevState };
        newFilterValues[name] = value;
        newFilterValues["send_to_details"] = SendTo.find(
          (address) => address.merged_address_ndx === value
        ).send_to_details;
        newFilterValues["send_to"] = SendTo.find(
          (address) => address.merged_address_ndx === value
        ).send_to;
        return newFilterValues;
      });
    } else {
      setSelectedRow((prevState) => {
        let newFilterValues = { ...prevState };
        newFilterValues[name] = value;
        return newFilterValues;
      });
    }
  };

  const { data, refetch } = useQuery(
    ["alerts-management-table"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/alerts-management-table`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const editTableColumns = [
    {
      title: "Enabled",
      field: "enabled",
      type: "boolean",
      cellStyle: {
        height: "40px",
      },
      filtering: true,
    },
    {
      title: "Location",
      field: "location",
      editable: "never",
      lookup: getLookupForDataSortedByField(data, "location"),
    },
    {
      title: "Parameter",
      field: "parameter",
      editable: "never",
      lookup: getLookupForDataSortedByField(data, "parameter"),
    },
    {
      title: "Trigger Type",
      field: "alert_type",
      editable: "never",
      lookup: getLookupForDataSortedByField(data, "alert_type"),
    },
    {
      title: "Trigger Value",
      field: "trigger_value",
      filtering: false,
      editable: "never",
    },
    {
      title: "Last Alert Sent",
      field: "last_alert_sent",
      type: "date",
      filtering: false,
      editable: "never",
    },
    {
      title: "Last Report",
      field: "most_recent_value",
      editable: "never",
    },
    {
      title: "Last Report Timestamp",
      field: "most_recent_timestamp",
      type: "date",
      filtering: false,
      editable: "never",
    },
  ];

  const handleSubmit = () => {
    if (selectedRow.alert_request_ndx) {
      handleUpdate().then();
    } else {
      handleAdd().then();
    }
  };

  const handleAdd = () => {
    return (async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/alerts-management-table`,
          selectedRow,
          { headers }
        );
        await refetch();
        doToast("success", "New entry was saved to the database");

        setSelectedRow(null);
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleUpdate = () => {
    return (async () => {
      try {
        if (selectedRow) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.put(
            `${process.env.REACT_APP_ENDPOINT}/api/alerts-management-table/${selectedRow.alert_request_ndx}`,
            selectedRow,
            { headers }
          );

          await refetch();
          doToast("success", "New data was updated to the database");

          setSelectedRow(null);
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleDelete = (oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.delete(
            `${process.env.REACT_APP_ENDPOINT}/api/alerts-management-table/${oldData.alert_request_ndx}`,
            { headers }
          );

          if (selectedRow.alert_request_ndx === oldData.alert_request_ndx) {
            setSelectedRow(null);
          }

          await refetch();
          doToast("success", "This entry was deleted from the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleUpdateCell = (newData, oldData, rowData, columnDef) => {
    return (async () => {
      try {
        if (oldData !== newData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };

          const patchData = {
            [columnDef.field]: newData,
          };

          await axios.patch(
            `${process.env.REACT_APP_ENDPOINT}/api/alerts-management-table/${rowData.alert_request_ndx}`,
            patchData,
            { headers }
          );

          await refetch();
          doToast("success", "New data was updated to the database");
        } else {
          return null;
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const enabledOptions = [
    {
      value: true,
      option: "Yes",
    },
    {
      value: false,
      option: "No",
    },
  ];

  const disableSubmit =
    ![true, false].includes(selectedRow?.enabled) ||
    !selectedRow?.alert_type_ndx ||
    !selectedRow?.location_ndx ||
    !selectedRow?.parameter_ndx ||
    !selectedRow?.trigger_value ||
    !selectedRow?.reset_hours;

  return (
    <React.Fragment>
      <Helmet title="Alerts Management" />
      <Typography variant="h3" gutterBottom display="inline">
        Alerts Management
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Alerts Management
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  {data ? (
                    <MaterialTable
                      cellEditable={{
                        onCellEditApproved: handleUpdateCell,
                      }}
                      editable={{
                        onRowDelete: handleDelete,
                      }}
                      id="Alerts Management"
                      title={`Alerts Management ${dateFormatter(
                        new Date(),
                        "MM/DD/YYYY, h:mm A"
                      )}`}
                      columns={editTableColumns}
                      data={
                        showStale
                          ? data
                          : data.filter((item) => item.alert_type_ndx !== 2)
                      }
                      localization={{
                        toolbar: { searchPlaceholder: "Search Alerts" },
                      }}
                      components={{
                        Container: (props) => <div {...props} />,
                      }}
                      actions={[
                        {
                          icon: CopyIcon,
                          tooltip: "Copy Data",
                          isFreeAction: true,
                          onClick: () => {
                            try {
                              copyToClipboard(data, editTableColumns, () =>
                                doToast(
                                  "success",
                                  "Data was copied to your clipboard."
                                )
                              );
                            } catch (error) {
                              const message =
                                error?.message ?? "Something went wrong";
                              doToast("error", message);
                            }
                          },
                        },
                        () => ({
                          icon: "edit",
                          tooltip: "Edit",
                          onClick: (event, rowData) => {
                            setSelectedRow(rowData);
                          },
                        }),
                        {
                          icon: showStale ? "toggle_on" : "toggle_off",
                          iconProps: {
                            style: {
                              color: showStale ? "#4CAF50" : "currentcolor",
                            },
                          },
                          tooltip: showStale
                            ? "Hide Stale Records"
                            : "Show Stale Records",
                          isFreeAction: true,
                          onClick: handleShowStale,
                        },
                        {
                          icon: "add_box",
                          tooltip: "Add",
                          isFreeAction: true,
                          onClick: () => {
                            setSelectedRow(defaultRowValues);
                          },
                        },
                      ]}
                      options={{
                        filtering: true,
                        emptyRowsWhenPaging: false,
                        showTitle: false,
                        columnsButton: true,
                        exportButton: true,
                        exportAllData: true,
                        addRowPosition: "first",
                        pageSize: 10,
                        pageSizeOptions: [10, 30, 50, 100, 200],
                        padding: "dense",
                        searchFieldAlignment: "left",
                        maxBodyHeight:
                          "calc(100vh - 64px - 48px - 106px - 48px - 64px - 204px)",
                      }}
                    />
                  ) : (
                    <Loader />
                  )}
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      {selectedRow && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="table-content"
                id="table-header"
              >
                <Typography variant="h4" ml={2}>
                  {!selectedRow.alert_request_ndx
                    ? "New Alert"
                    : `Editing Alert "${selectedRow.alert_request_ndx}"`}
                </Typography>
              </AccordionSummary>
              <Panel>
                <AccordionDetails>
                  <Grid container spacing={10}>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={2}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={
                          ![false, true].includes(selectedRow.enabled) &&
                          !selectedRow.alert_request_ndx
                        }
                      >
                        <InputLabel id="enabled">Enabled</InputLabel>
                        <Select
                          labelId="enabled-label"
                          id="enabled"
                          label="Enabled"
                          value={
                            [false, true].includes(selectedRow.enabled)
                              ? selectedRow.enabled
                              : ""
                          }
                          onChange={(e) =>
                            handleUpdateSelectedRow("enabled", e.target.value)
                          }
                        >
                          {enabledOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.option}
                            </MenuItem>
                          ))}
                        </Select>
                        {![false, true].includes(selectedRow.enabled) &&
                          !selectedRow.alert_request_ndx && (
                            <FormHelperText error>
                              *Required field
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={9}
                      md={5}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={!selectedRow.alert_type_ndx}
                        required
                      >
                        <InputLabel id="alert-type">Alert Type</InputLabel>
                        <Select
                          labelId="alert-type-label"
                          id="alert-type"
                          label="Alert Type"
                          name="alert_type_ndx"
                          value={selectedRow.alert_type_ndx || ""}
                          onChange={(e) => {
                            handleUpdateSelectedRow(
                              "alert_type_ndx",
                              e.target.value
                            );
                          }}
                        >
                          {AlertTypes.map((option) => (
                            <MenuItem
                              key={option.alert_type_ndx}
                              value={option.alert_type_ndx}
                            >
                              {option.alert_type}
                            </MenuItem>
                          ))}
                        </Select>
                        {!selectedRow.alert_type_ndx && (
                          <FormHelperText error>*Required field</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={5}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={!selectedRow.location_ndx}
                        required
                      >
                        <InputLabel id="location">Location</InputLabel>
                        <Select
                          labelId="location-label"
                          id="location"
                          label="Location"
                          name="location_ndx"
                          value={selectedRow.location_ndx || ""}
                          onChange={(e) => {
                            if (
                              !Locations.find(
                                (location) =>
                                  e.target.value === location.location_ndx
                              ).assoc_parameter_ndx.includes(
                                selectedRow.parameter_ndx
                              )
                            ) {
                              handleUpdateSelectedRow("parameter_ndx", null);
                            }
                            handleUpdateSelectedRow(
                              "location_ndx",
                              e.target.value
                            );
                          }}
                        >
                          {Locations.map((option) => (
                            <MenuItem
                              key={option.location_ndx}
                              value={option.location_ndx}
                            >
                              {option.location}
                            </MenuItem>
                          ))}
                        </Select>
                        {!selectedRow.location_ndx && (
                          <FormHelperText error>*Required field</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={!selectedRow.parameter_ndx}
                        required
                      >
                        <InputLabel id="location">Parameter</InputLabel>
                        <Select
                          labelId="parameter-label"
                          id="parameter"
                          label="Parameter"
                          name="parameter_ndx"
                          value={selectedRow.parameter_ndx || ""}
                          onChange={(e) =>
                            handleUpdateSelectedRow(
                              "parameter_ndx",
                              e.target.value
                            )
                          }
                        >
                          {Parameters.filter((parameter) =>
                            parameter.assoc_location_ndx.includes(
                              selectedRow.location_ndx
                            )
                          ).map((option) => (
                            <MenuItem
                              key={option.parameter_ndx}
                              value={option.parameter_ndx}
                            >
                              {option.parameter}
                            </MenuItem>
                          ))}
                        </Select>
                        {!selectedRow.location_ndx ? (
                          <FormHelperText error>
                            *Select a location first
                          </FormHelperText>
                        ) : (
                          !selectedRow.parameter_ndx && (
                            <FormHelperText error>
                              *Required field
                            </FormHelperText>
                          )
                        )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={3}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        required
                        type="number"
                        error={
                          selectedRow.trigger_value !== 0 &&
                          !selectedRow.trigger_value
                        }
                        helperText={
                          selectedRow.trigger_value !== 0 &&
                          !selectedRow.trigger_value
                            ? "*Required field"
                            : ""
                        }
                        variant="outlined"
                        label="Trigger Value"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "trigger_value",
                            e.target.value
                          )
                        }
                        value={selectedRow.trigger_value ?? ""}
                        InputProps={{
                          inputProps: {
                            onKeyPress: (e) => {
                              if (e.key === "e") {
                                e.preventDefault();
                              }
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={3}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        required
                        type="number"
                        error={
                          selectedRow.reset_hours !== "0" &&
                          !selectedRow.reset_hours
                        }
                        helperText={
                          selectedRow.reset_hours !== "0" &&
                          !selectedRow.reset_hours
                            ? "*Required field"
                            : ""
                        }
                        variant="outlined"
                        label="Reset Hours"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "reset_hours",
                            +e.target.value
                          )
                        }
                        value={selectedRow.reset_hours ?? ""}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            onKeyPress: (e) => {
                              if (e.key === "e") {
                                e.preventDefault();
                              }
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={!selectedRow.merged_address_ndx}
                        required
                      >
                        <InputLabel id="send-to">Send Alerts To</InputLabel>
                        <Select
                          labelId="send-to-label"
                          id="send-to"
                          label="Send Alerts To"
                          name="merged_address_ndx"
                          value={selectedRow.merged_address_ndx || ""}
                          onChange={(e) => {
                            handleUpdateSelectedRow(
                              "merged_address_ndx",
                              e.target.value
                            );
                          }}
                        >
                          {SendTo.map((option) => (
                            <MenuItem
                              key={option.merged_address_ndx}
                              value={option.merged_address_ndx}
                            >
                              {option.send_to}
                            </MenuItem>
                          ))}
                        </Select>
                        {!selectedRow.merged_address_ndx && (
                          <FormHelperText error>*Required field</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Custom Message Subject"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "custom_subject",
                            e.target.value
                          )
                        }
                        value={selectedRow.custom_subject || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Custom Location Name"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "custom_loc_name",
                            e.target.value
                          )
                        }
                        value={selectedRow.custom_loc_name || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Notes"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow("notes", e.target.value)
                        }
                        value={selectedRow.notes || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Send To Details"
                        style={{ width: "100%" }}
                        disabled
                        value={
                          (selectedRow.merged_address_ndx &&
                            SendTo.find(
                              (item) =>
                                item.merged_address_ndx ===
                                selectedRow.merged_address_ndx
                            ).send_to_details) ||
                          ""
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Last Alert Sent To Details"
                        style={{ width: "100%" }}
                        disabled
                        value={selectedRow.last_alert_sent_to_details ?? ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={7}
                      style={{
                        position: "relative",
                      }}
                    />
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Button
                        style={{ width: "100%", height: "44px" }}
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => setSelectedRow(null)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Button
                        style={{ width: "100%", height: "44px" }}
                        type="submit"
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={disableSubmit}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Panel>
            </Accordion>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default AlertsManagement;

import { CircularProgress } from "@material-ui/core";
import styled from "styled-components/macro";
import NumberFormat from "react-number-format";

export const getButtonIcon = (isFormSubmitting) => {
  if (isFormSubmitting) {
    return <CircularProgress color="inherit" size={20} />;
  }
};

export const getButtonText = (isFormSubmitting, isNewWell = false) => {
  if (isFormSubmitting) {
    if (isNewWell) {
      return "Creating";
    } else {
      return "Saving";
    }
  }
  if (isNewWell) {
    return "Create";
  } else {
    return "Save";
  }
};

export function isValidHexColor(color) {
  const hexColorRegex = /^#([0-9a-fA-F]{3}){1,2}$/;
  return hexColorRegex.test(color);
}

export function colorToHex(color) {
  if (!color) return "#000000";
  // Create a temporary canvas element
  const canvas = document.createElement("canvas");
  canvas.width = 1;
  canvas.height = 1;
  const ctx = canvas.getContext("2d");

  // Set the fill style to the input color
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, 1, 1);

  // Get the image data of the filled pixel
  const imageData = ctx.getImageData(0, 0, 1, 1);
  const data = imageData.data;

  // Convert RGB values to hexadecimal
  const hex = Array.from(data)
    .slice(0, 3)
    .map((value) => value.toString(16).padStart(2, "0"))
    .join("");

  // Prepend '#' to the hexadecimal value
  const hexCode = `#${hex}`;

  return hexCode;
}
export const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

export function NumberFormatCustom(props) {
  const { config, inputRef, ...other } = props;
  return (
    <NumberFormat
      getInputRef={inputRef}
      decimalScale={config.decimalScale ?? 0}
      fixedDecimalScale={config.fixedDecimalScale ?? false}
      max={5}
      {...other}
    />
  );
}

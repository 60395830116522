import React, { forwardRef } from "react";
import { withTheme } from "styled-components/macro";

import { Bar, Chart } from "react-chartjs-2";
import "chartjs-plugin-zoom";
import zoomPlugin from "chartjs-plugin-zoom";
import { dateFormatter, lineColors } from "../../../utils";
Chart.register(zoomPlugin);

const DashBarChart = forwardRef(({ data, theme, filterValues }, ref) => {
  const plugins = [
    {
      id: "chartFillBackground",
      beforeDraw: (chart) => {
        const ctx = chart.ctx;
        ctx.fillStyle = lineColors.lightGray;
        ctx.fillRect(0, 0, chart.width, chart.height);
      },
    },
  ];

  const options = {
    layout: {
      padding: 10,
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "x",
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItems) {
            return `${tooltipItems.dataset.parameter}: ${tooltipItems.formattedValue} ${tooltipItems.dataset.units}`;
          },
          footer: (tooltipItems) => {
            return [
              tooltipItems[0].dataset.source[tooltipItems[0].dataIndex]
                .split(",")
                .join(", "),
              dateFormatter(
                tooltipItems[0].dataset.timestamp[tooltipItems[0].dataIndex]
                  .split(",")
                  .join(", "),
                "MM/DD/YYYY, h:mm A"
              ),
            ];
          },
        },
        displayColors: false,
      },
      title: {
        text:
          filterValues.hours === 0
            ? `${dateFormatter(new Date(), "MM/DD/YYYY")} - Last Report`
            : filterValues.hours === 1
            ? `${dateFormatter(new Date(), "MM/DD/YYYY")} - ${
                filterValues.hours
              } Hour Ago`
            : `${dateFormatter(new Date(), "MM/DD/YYYY")} - ${
                filterValues.hours
              } Hours Ago`,
        display: true,
        align: "start",
        color: theme.palette.text.secondary,
        padding: {
          bottom: 30,
        },
        font: {
          size: 24,
        },
      },
    },
    scales: {
      x: {
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          color: theme.palette.text.secondary,
        },
      },

      y: {
        // position: "left",
        display: true,
        grid: {
          // display: false,
          borderColor: theme.palette.text.secondary,
        },
        ticks: {
          color: theme.palette.text.secondary,
        },
        title: {
          display: true,
          text: `${data.datasets[0].parameter}, ${data.datasets[0].units} `,
          color: theme.palette.text.secondary,
        },
      },
    },
  };

  return (
    <>
      <Bar
        ref={ref}
        data={data}
        options={options}
        type="bar"
        plugins={plugins}
      />
    </>
  );
});

export default withTheme(DashBarChart);

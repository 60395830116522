export const LOCATIONS_TABLE_COLUMNS = [
  {
    title: "Location ID",
    field: "location_id",
    cellStyle: {
      width: 200,
      minWidth: 200,
    },
  },
  {
    title: "Display Abbreviation",
    field: "location_display_label",
    cellStyle: {
      width: 200,
      minWidth: 200,
    },
  },
  {
    title: "Location Full Name",
    field: "location_name",
    cellStyle: {
      width: 200,
      minWidth: 200,
    },
  },
  {
    title: "Notes",
    field: "notes",
    filtering: false,
    cellStyle: {
      width: 400,
      minWidth: 400,
    },
  },
];

export const BOOLEAN_OPTIONS = [
  {
    value: true,
    option: "Yes",
  },
  {
    value: false,
    option: "No",
  },
];

export const DEFAULT_BASIC_LOCATION_INFO_STATE = {
  location_ndx: null,
  location_id: null,
  location_display_label: null,
  location_name: null,
  location_type_ndx: null,
  waterbody_ndx: null,
  legend_color: null,
  display_order: null,
  notes: null,
  latitude: null,
  longitude: null,
  exclude_from_map: false,
  linked_excel_include: true,
};

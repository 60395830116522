export const WATERBODIES_TABLE_COLUMNS = [
  {
    title: "Waterbody Name",
    field: "waterbody_name",
    cellStyle: {
      width: 200,
      minWidth: 200,
    },
  },
  {
    title: "Notes",
    field: "notes",
    cellStyle: {
      width: 600,
      minWidth: 600,
    },
  },
  {
    title: "Include?",
    field: "include",
    type: "boolean",
  },
  {
    title: "Display Order",
    field: "display_order",
  },
];

export const BOOLEAN_OPTIONS = [
  {
    value: true,
    option: "Yes",
  },
  {
    value: false,
    option: "No",
  },
];

export const DEFAULT_WATERBODY_INFO_STATE = {
  waterbody_ndx: null,
  waterbody_name: null,
  display_order: null,
  include: true,
  notes: null,
};

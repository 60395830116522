import React, { useRef, useState } from "react";
import { useQueries } from "react-query";
import axios from "axios";
import { groupByValueArray, standardizeColor } from "../../../utils";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography as MuiTypography,
  lighten,
  Paper as MuiPaper,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import TimeseriesLineChart from "../../../components/graphs/TimeseriesLineChart";
import SystemGraphsMap from "../../../components/map/SystemGraphsMap";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Loader from "../../../components/Loader";
import { useAuth0 } from "@auth0/auth0-react";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);

const TimeseriesContainer = styled.div`
  height: 460px;
  width: 100%;
`;

const MapContainer = styled.div`
  height: 406px;
  width: 100%;
`;

const StyledToggleButton = styled(ToggleButton)`
  width: 100px;
  &.Mui-selected,
  &.Mui-selected:hover {
    color: ${({ theme }) =>
      theme.palette.common.white}; // Accessing white color from the theme
    background-color: ${({ theme }) =>
      theme.palette.primary.main}; // Accessing primary color from the theme
  }
`;

const groupAndSortData = (data, key) =>
  groupByValueArray(data, key).sort((v1, v2) => {
    return v1[0].display_order < v2[0].display_order
      ? -1
      : v1[0].display_order > v2[0].display_order
      ? 1
      : 0;
  });

const createGraphData = (data) => ({
  datasets: data.map((location) => {
    return {
      data: location.map((item) => {
        return {
          ...item,
          x: item.collect_timestamp,
          y: item.result_value,
        };
      }),
      yAxisID: "yL",
      units: location[0].unit_display_label,
      pointStyle: "circle",
      fill: false,
      borderWidth: 2,
      spanGaps: true,
      pointRadius: 2,
      pointHoverRadius: 4,
      label: `${location[0].location_display_label}`,
      borderColor: location[0].legend_color,
      backgroundColor: lighten(standardizeColor(location[0].legend_color), 0.5),
      tension: 0.5,
    };
  }),
  title: `${data[0]?.[0]?.parameter_display_label ?? "N/A"} / ${
    data[0]?.[0]?.unit_display_label ?? "N/A"
  }`,
});

const useFetchData = (id, intervals) => {
  const { getAccessTokenSilently } = useAuth0();

  const fetchUrl = (interval) =>
    `${process.env.REACT_APP_ENDPOINT}/api/fixed-stations-system-graphs/${id}/data/${interval}`;

  const queries = intervals.map((interval) => ({
    queryKey: ["fixed-stations-system-graphs/data", id, interval.key],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const { data } = await axios.get(fetchUrl(interval.key), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const groupedAndSortedData = groupAndSortData(data, "location_ndx");
      return createGraphData(groupedAndSortedData);
    },
    refetchOnWindowFocus: false,
  }));

  const results = useQueries(queries);

  return results.map((result, index) => ({
    ...result,
    key: intervals[index].key,
    label: intervals[index].label,
  }));
};

const FixedStationsSystemGraphs = ({ config: { displayId, intervals } }) => {
  const saveRef = useRef(null);

  const results = useFetchData(displayId, intervals);
  const [graphType, setGraphType] = useState(intervals[0].key);

  const pageTitle = "Riverside Flows";

  const error = results.some((result) => result.error && result.error.message);
  if (error) {
    return "An error has occurred: " + error.message;
  }

  return (
    <>
      <Helmet title={pageTitle} />
      <Typography variant="h3" gutterBottom display="inline">
        {pageTitle}
      </Typography>
      <Divider my={6} />

      <Grid container spacing={3}>
        <Grid item xs>
          <ToggleButtonGroup
            exclusive
            color="primary"
            value={graphType}
            onChange={(event, newGraphType) => {
              if (newGraphType !== null) {
                setGraphType(newGraphType);
              }
            }}
            aria-label="Graph interval selection"
            style={{ height: "60px" }}
          >
            {results.map((result) => (
              <StyledToggleButton
                key={result.key}
                value={result.key}
                disabled={result.isFetching || result.isError}
              >
                {result.isFetching ? <Loader /> : result.label}
              </StyledToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={12}>
          {results.map((result) => {
            if (graphType === result.key) {
              return (
                <Paper p={3} key={result.key}>
                  <TimeseriesContainer>
                    <TimeseriesLineChart
                      data={result.data}
                      error={result.error}
                      isLoading={result.isFetching}
                      reverseLegend={false}
                      yLLabel={result.data?.title}
                      xLabelUnit="day"
                      ref={saveRef}
                      title={result.data?.title}
                      tooltipFormat={
                        graphType === "15min"
                          ? "MM-DD-YYYY, h:mm A"
                          : "MM-DD-YYYY"
                      }
                    />
                  </TimeseriesContainer>
                </Paper>
              );
            }
            return null;
          })}
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="map"
              id="map"
            >
              <Typography variant="h4" ml={2}>
                Map
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MapContainer>
                <SystemGraphsMap
                  endpoint={`fixed-stations-system-graphs/${displayId}/map`}
                />
              </MapContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default FixedStationsSystemGraphs;

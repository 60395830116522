import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Unauthorized from "./Unauthorized";
import { useParams } from "react-router-dom";

const FixedStationsGuard = ({ children }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { displayId } = useParams();

  if (isLoading) {
    return <></>;
  }

  if (!user || !isAuthenticated) {
    return <Unauthorized />;
  }

  const userRoles = user[`${process.env.REACT_APP_AUDIENCE}/roles`];
  const userAppMetadataFixedStationsDisplayId =
    user[`${process.env.REACT_APP_AUDIENCE}/fixed_stations_display_id`];

  const isDeveloper = userRoles?.includes("Developer");

  const hasAccess =
    (userRoles?.includes("Fixed Stations System Graphs") &&
      userAppMetadataFixedStationsDisplayId?.includes(displayId)) ||
    isDeveloper;

  if (!hasAccess) {
    return <Unauthorized />;
  }

  return children;
};

export default FixedStationsGuard;

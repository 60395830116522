import React from "react";
import FixedStationsSystemGraphs from "./FixedStationsSystemGraphs";
import { useParams } from "react-router-dom";

const FixedStationsPage = () => {
  const { displayId } = useParams();

  const CONFIG = {
    displayId,
    intervals: [
      { key: "15min", label: "15 Min" },
      { key: "daily", label: "Daily" },
    ],
  };
  return <FixedStationsSystemGraphs config={CONFIG} />;
};

export default FixedStationsPage;

import React from "react";
import SystemGraphs from "./SystemGraphs";

const CanalPiezometers = () => {
  const defaultParameter = 1778;

  const pageTitle = "Canal Piezometers";

  const endpoints = [
    { label: "15 Minutes", value: "display008-data15-min" },
    { label: "Daily", value: "display008-data-daily" },
  ];

  const parametersEndpoint = "display008-lookup-parameter";

  const mapEndpoint = "display008-map-points";

  return (
    <>
      <SystemGraphs
        defaultParameter={defaultParameter}
        pageTitle={pageTitle}
        endpoints={endpoints}
        parametersEndpoint={parametersEndpoint}
        mapEndpoint={mapEndpoint}
      />
    </>
  );
};

export default CanalPiezometers;

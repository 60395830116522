import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import {
  Speed,
  BatteryCharging90,
  LocalDrink,
  Opacity,
} from "@material-ui/icons";
import { dateFormatter } from "../utils";

const ModelCardRoot = styled(Card)`
  border: 1px solid #eaecf0;
  margin: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ModelCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const ContentBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DataChip = styled(Chip)`
  height: 24px;
  width: 70px;
  margin-left: 8px;
`;

const DateChip = styled(Chip)`
  height: 24px;
  margin-left: auto;
  width: auto;
`;

const ModelCardActions = styled(CardActions)`
  background: #fafafa;
  border-top: 1px solid #eaecf0;
  height: 45px;
  justify-content: space-between;
`;

const TruncatedTypographyTitle = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ActionBox = styled(Box)`
  margin-left: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ViewButton = styled(Button)`
  margin-left: auto;
  margin-right: 8px;
`;

const isDataPresent = (dataField) =>
  dataField !== null && dataField !== undefined;

const locationTypeToIcon = {
  "Canal/Ditch Instream": Opacity,
  "Flow Control Gate": Speed,
  "Recharge Structure": BatteryCharging90,
  Reservoir: LocalDrink,
};

const DataField = ({ title, value }) => {
  const formattedValue =
    isDataPresent(value) && title === "Gate Open %" ? `${value}%` : value;

  return (
    isDataPresent(value) && (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography variant="h6">{title}</Typography>
        <DataChip
          color="primary"
          variant="outlined"
          label={formattedValue ?? "--"}
        />
      </Box>
    )
  );
};

export const MobileCard = ({ data = {} }) => {
  const Icon = locationTypeToIcon[data?.location_type_desc] ?? null;

  return (
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <ModelCardRoot variant="outlined">
        <ModelCardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <TruncatedTypographyTitle variant="h3">
              {data?.location_name ?? "--"}
            </TruncatedTypographyTitle>
          </Box>

          <DataField title="Flow" value={data?.flow_cfs} />
          <DataField title="Stage" value={data?.stage_ft} />
          <DataField title="GW Depth" value={data?.gw_depth_ft} />
          <DataField title="Water Level" value={data?.water_level_ft} />
          <DataField title="Gate Open %" value={data?.gate_open_pct} />

          {isDataPresent(data?.last_report) && (
            <ContentBox>
              <Typography variant="h6">Last Report</Typography>
              <DateChip
                color="primary"
                variant="outlined"
                label={dateFormatter(data.last_report, "MM/DD/YYYY, h:mm A")}
              />
            </ContentBox>
          )}
        </ModelCardContent>
        <ModelCardActions>
          <ActionBox>
            {!!Icon && <Icon style={{ marginRight: "4px" }} />}
            <Typography variant="body2">
              {data?.location_type_desc ?? "--"}
            </Typography>
          </ActionBox>
          {data?.location_link && (
            <ViewButton
              size="small"
              variant="contained"
              href={data.location_link}
            >
              View
            </ViewButton>
          )}
        </ModelCardActions>
      </ModelCardRoot>
    </Grid>
  );
};

import { Box, Typography } from "@material-ui/core";
import FilterControl from "../filters/filterControl";
import Filter from "../filters/filter";
import WellStylesControl from "../controls/wellStylesControl";
import React from "react";
import styled from "styled-components/macro";
import useLayerStyles from "../hooks/useLayerStyles";

const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex: 1 1 0;
`;

const FiltersBar = ({
  filterValues,
  handleFilterValues,
  handleSelectAll,
  handleSelectNone,
  updateLayerStyles,
  isMapLoaded,
}) => {
  const { activeStyle, handleActiveStyle, styleOptions } = useLayerStyles({
    onLayerStyleChange: updateLayerStyles,
    isMapLoaded,
  });

  const getMoreFiltersCount = (filterValues) => {
    const keys = ["hasFlowGraph", "hasStageGraph", "hasWaterlevelsGraph"];
    return keys.filter((key) => filterValues[key].value).length;
  };

  return (
    <>
      <FiltersSection>
        <FiltersContainer>
          <FilterControl
            width="166px"
            appliedCount={filterValues?.reportingStatus?.value?.length}
            label={filterValues?.reportingStatus?.label}
          >
            <Filter
              label={filterValues?.reportingStatus?.label}
              name={filterValues?.reportingStatus?.name}
              onChange={handleFilterValues}
              onSelectAll={handleSelectAll}
              onSelectNone={handleSelectNone}
              options={filterValues?.reportingStatus?.options}
              type={filterValues?.reportingStatus?.type}
              value={filterValues?.reportingStatus?.value}
            />
          </FilterControl>
          <FilterControl
            width="152px"
            appliedCount={filterValues?.locationTypes?.value?.length}
            label={filterValues?.locationTypes?.label}
          >
            <Filter
              label={filterValues?.locationTypes?.label}
              name={filterValues?.locationTypes?.name}
              onChange={handleFilterValues}
              onSelectAll={handleSelectAll}
              onSelectNone={handleSelectNone}
              options={filterValues?.locationTypes?.options}
              type={filterValues?.locationTypes?.type}
              value={filterValues?.locationTypes?.value}
            />
          </FilterControl>
          <FilterControl
            width="132px"
            appliedCount={getMoreFiltersCount(filterValues)}
            label="More Filters"
          >
            <Box display="flex" flexDirection="column">
              <Filter
                label="Has Flows"
                name="hasFlowGraph"
                onChange={handleFilterValues}
                type="boolean"
                value={filterValues?.hasFlowGraph?.value}
              />
              <Filter
                label="Has Stage Data"
                name="hasStageGraph"
                onChange={handleFilterValues}
                type="boolean"
                value={filterValues?.hasStageGraph?.value}
              />
              <Filter
                label="Has Water Levels"
                name="hasWaterlevelsGraph"
                onChange={handleFilterValues}
                type="boolean"
                value={filterValues?.hasWaterlevelsGraph?.value}
              />
            </Box>
          </FilterControl>
        </FiltersContainer>
      </FiltersSection>

      <FiltersSection>
        <FiltersContainer>
          <FilterControl width="204px" label={`Color by ${activeStyle.name}`}>
            <Typography variant="subtitle1" gutterBottom>
              Color Data Points by
            </Typography>
            <WellStylesControl
              label="Color Data Points by"
              name="wellStyles"
              onChange={handleActiveStyle}
              options={styleOptions}
              value={activeStyle.id}
            />
          </FilterControl>
        </FiltersContainer>
      </FiltersSection>
    </>
  );
};

export default FiltersBar;

import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import useFetchData from "../../../../hooks/useFetchData";
import { DEFAULT_BASIC_LOCATION_INFO_STATE } from "../LocationsSetup/LocationsConstants";
import { colorToHex } from "../utils";

export const useBasicLocationInfo = (selectedLocation) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useStateIfMounted(
    DEFAULT_BASIC_LOCATION_INFO_STATE
  );
  const [isDirty, setIsDirty] = useStateIfMounted(false);

  const handleUpdateState = (name, value) => {
    if (!isDirty) setIsDirty(true);
    setEditorState((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const handleResetState = () => {
    setIsDirty(false);
    if (selectedLocation) {
      setEditorState(data);
    } else {
      setEditorState(DEFAULT_BASIC_LOCATION_INFO_STATE);
    }
  };

  const { data, isFetching, error, refetch } = useQuery(
    ["data-list-locations", selectedLocation],
    async () => {
      if (!selectedLocation) {
        setIsDirty(false);
        setEditorState(DEFAULT_BASIC_LOCATION_INFO_STATE);
        return {};
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-list-locations/${selectedLocation}`,
          { headers }
        );
        setIsDirty(false);
        setEditorState({
          ...data,
          legend_color: colorToHex(data.legend_color),
        });
        return { ...data, legend_color: colorToHex(data.legend_color) };
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [locationTypeLookup] = useFetchData("list-location-types", [], true);
  const [waterbodyGroupLookup] = useFetchData("list-waterbodies", [], true);

  return {
    data,
    isLoading: isFetching,
    error,
    refetch,
    isDirty,
    lookups: {
      locationTypes: locationTypeLookup,
      waterbodies: waterbodyGroupLookup,
    },
    handleUpdateState,
    handleResetState,
    state: editorState,
    setter: setEditorState,
  };
};

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import { useSubmitData } from "../hooks/useSubmitData";
import { useBasicLocationInfo } from "../hooks/useBasicLocationInfo";
import {
  getButtonIcon,
  getButtonText,
  isValidHexColor,
  NumberFormatCustom,
} from "../utils";
import Loader from "../../../../components/Loader";
import LatLongMap from "../../../../components/map/LatLongMap";
import { BOOLEAN_OPTIONS } from "./LocationsConstants";

function BasicLocationInfo({
  selectedLocation,
  setSelectedLocation,
  refetchLocations = () => {},
  isEditMode = false,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    lookups,
  } = useBasicLocationInfo(selectedLocation);

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedLocation,
    setSelectedLocation,
    state,
    "data-list-locations",
    "location_ndx",
    refetch,
    refetchLocations
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.location_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.location_ndx)
              ? "none"
              : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Basic Location Information</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  error={!state.location_id}
                  variant="outlined"
                  label="Location ID"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleUpdateState("location_id", e.target.value)
                  }
                  value={state.location_id ?? ""}
                />
                {!state.location_id && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  error={!state.location_display_label}
                  variant="outlined"
                  label="Display Abbreviation"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleUpdateState("location_display_label", e.target.value)
                  }
                  value={state.location_display_label ?? ""}
                />
                {!state.location_display_label && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  error={!state.location_name}
                  variant="outlined"
                  label="Location Full Name"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleUpdateState("location_name", e.target.value)
                  }
                  value={state.location_name ?? ""}
                />
                {!state.location_name && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="color"
                id="legend-color"
                value={state.legend_color ?? ""}
                onChange={(e) =>
                  handleUpdateState("legend_color", e.target.value)
                }
                style={{ marginRight: "16px" }}
              />
              <FormControl style={{ width: "100%" }}>
                <TextField
                  required
                  error={
                    !isValidHexColor(state.legend_color) ||
                    state?.legend_color?.length !== 7
                  }
                  label="Legend Color (hex code)"
                  value={state.legend_color ?? ""}
                  onChange={(e) =>
                    handleUpdateState("legend_color", e.target.value)
                  }
                />
                {(!isValidHexColor(state.legend_color) ||
                  state?.legend_color?.length !== 7) && (
                  <FormHelperText error>
                    *Requires valid 6 digit hex code
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  error={!state.display_order && state.display_order !== 0}
                  required
                  type="number"
                  variant="outlined"
                  label="Display Order"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleUpdateState("display_order", +e.target.value);
                  }}
                  value={state.display_order ?? ""}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      onKeyPress: (e) => {
                        if (["e", ".", "-"].includes(e.key)) {
                          e.preventDefault();
                        }
                      },
                    },
                  }}
                />
                {!state.display_order && state.display_order !== 0 && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.location_type_ndx}
                required
              >
                {!lookups?.locationTypes.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="location-type">Location Type</InputLabel>
                    <Select
                      labelId="location type"
                      id="location-type"
                      label="Location Type"
                      name="location_type_ndx"
                      value={state.location_type_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("location_type_ndx", e.target.value);
                      }}
                    >
                      {lookups.locationTypes.map((option) => (
                        <MenuItem
                          key={option.location_type_ndx}
                          value={option.location_type_ndx}
                        >
                          {option.location_type_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {!state.location_type_ndx && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.waterbody_ndx}
                required
              >
                {!lookups?.waterbodies.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="waterbody">Waterbody</InputLabel>
                    <Select
                      labelId="waterbody"
                      id="waterbody"
                      label="Waterbody"
                      name="waterbody_ndx"
                      value={state.waterbody_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("waterbody_ndx", e.target.value);
                      }}
                    >
                      {lookups.waterbodies.map((option) => (
                        <MenuItem
                          key={option.waterbody_ndx}
                          value={option.waterbody_ndx}
                        >
                          {option.waterbody_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {!state.waterbody_ndx && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Notes"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("notes", e.target.value)}
                value={state.notes ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <LatLongMap
                filterValues={state}
                setFilterValues={handleUpdateState}
                isEditorDirty={isDirty}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                name="latitude"
                error={state.latitude > 90 || state.latitude < -90}
                helperText={
                  state.latitude > 90 || state.latitude < -90
                    ? "Invalid coordinate"
                    : ""
                }
                variant="outlined"
                label="Latitude"
                style={{ width: "100%" }}
                InputProps={{
                  inputProps: {
                    config: {
                      decimalScale: 5,
                      fixedDecimalScale: true,
                    },
                  },
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) => handleUpdateState("latitude", e.target.value)}
                value={state.latitude ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              style={{
                position: "relative",
              }}
            >
              <TextField
                name="longitude"
                error={state.longitude > 180 || state.longitude < -180}
                helperText={
                  state.longitude > 180 || state.longitude < -180
                    ? "Invalid coordinate"
                    : ""
                }
                variant="outlined"
                label="Longitude"
                style={{ width: "100%" }}
                InputProps={{
                  inputProps: {
                    config: {
                      decimalScale: 5,
                      fixedDecimalScale: true,
                    },
                  },
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) => handleUpdateState("longitude", e.target.value)}
                value={state.longitude ?? ""}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="enabled">Hide from Map</InputLabel>
                <Select
                  labelId="hide-from-map-label"
                  id="hide-from-map"
                  label="Hide from Map"
                  value={state?.exclude_from_map ?? ""}
                  onChange={(e) =>
                    handleUpdateState("exclude_from_map", e.target.value)
                  }
                >
                  {BOOLEAN_OPTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="enabled">Linked Excel</InputLabel>
                <Select
                  labelId="linked-excel-label"
                  id="linked-excel"
                  label="Linked Excel"
                  value={state?.linked_excel_include ?? ""}
                  onChange={(e) =>
                    handleUpdateState("linked_excel_include", e.target.value)
                  }
                >
                  {BOOLEAN_OPTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={handleResetState}>
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={
              !isDirty ||
              !state.location_id ||
              !state.location_display_label ||
              !state.location_name ||
              !state.location_type_ndx ||
              !state.waterbody_ndx ||
              !isValidHexColor(state.legend_color) ||
              state?.legend_color?.length !== 7 ||
              isFormSubmitting
            }
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting, selectedLocation === 0)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default BasicLocationInfo;

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { dateFormatter } from "../utils";

const ModelCardRoot = styled(Card)`
  border: 1px solid #eaecf0;
  margin: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ModelCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const DataChip = styled(Chip)`
  height: 24px;
  width: 70px;
  margin-left: 8px;
`;

const LastReportChip = styled(Chip)`
  height: 24px;
  margin-left: 8px;
`;

const ModelCardActions = styled(CardActions)`
  background: #fafafa;
  border-top: 1px solid #eaecf0;
  flex-direction: column;
  align-items: start;
`;

const TruncatedTypographyTitle = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const CurrentConditionsCard = ({ data = {} }) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <ModelCardRoot variant="outlined">
        <ModelCardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <TruncatedTypographyTitle variant="h3">
              {data?.location_display_label ?? "--"}
            </TruncatedTypographyTitle>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography variant="h6">% Open</Typography>

            <DataChip
              color="primary"
              variant="outlined"
              label={data?.result_value ?? "--"}
            />
          </Box>
        </ModelCardContent>
        <ModelCardActions>
          <Box style={{ marginLeft: "8px", marginBottom: "5px" }}>
            <Typography variant="h6">Last Report</Typography>
          </Box>

          <Box>
            <LastReportChip
              color="primary"
              variant="outlined"
              label={
                dateFormatter(data?.last_report, "MM/DD/YYYY, h:mm A") ?? "--"
              }
              style={{ marginLeft: "auto", marginRight: "8px" }}
            />
          </Box>
        </ModelCardActions>
      </ModelCardRoot>
    </Grid>
  );
};

export default CurrentConditionsCard;

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  Divider as MuiDivider,
  Grid as MuiGrid,
  AccordionSummary,
  Typography as MuiTypography,
  TextField,
} from "@material-ui/core";

import Panel from "../../../components/panels/Panel";
import DataAdminTable from "../../../components/DataAdminTable";
import Loader from "../../../components/Loader";
import useFetchData from "../../../hooks/useFetchData";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

function Contacts() {
  const { getAccessTokenSilently } = useAuth0();

  const [DisableDeleteArray] = useFetchData("view-contacts-used", [], true);

  const endpoint = "list-contacts";
  const ndxField = "contact_ndx";
  const editTableColumns = [
    {
      title: "Contact Address",
      field: "contact_address",
      editComponent: (props) => (
        <TextField
          required
          error={!props.value}
          helperText={!props.value ? "*Required field" : ""}
          value={props.value || ""}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      validate: (rowData) =>
        !rowData.contact_address
          ? {
              isValid: false,
            }
          : true,
    },
    {
      title: "Contact Name",
      field: "contact_name",
      editComponent: (props) => (
        <TextField
          required
          error={!props.value}
          helperText={!props.value ? "*Required field" : ""}
          value={props.value || ""}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      validate: (rowData) =>
        !rowData.contact_name
          ? {
              isValid: false,
            }
          : true,
    },
    {
      title: "Contact Organization",
      field: "contact_org",
      editComponent: (props) => (
        <TextField
          required
          error={!props.value}
          helperText={!props.value ? "*Required field" : ""}
          value={props.value || ""}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      validate: (rowData) =>
        !rowData.contact_org
          ? {
              isValid: false,
            }
          : true,
    },
    {
      title: "Remark",
      field: "remark",
      editComponent: (props) => (
        <TextField
          type="text"
          value={props.value || ""}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
    },
    {
      title: "Receive Text Message",
      field: "send_sms",
      type: "boolean",
      initialEditValue: false,
    },
    {
      title: "Receive Phone Call",
      field: "send_call",
      type: "boolean",
      initialEditValue: false,
    },
  ];
  const title = "Contacts";

  const { data, error, isFetching, refetch } = useQuery(
    [endpoint],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const [filteredData, setFilteredData] = useState(null);
  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  if (error) return "An error has occurred: " + error.message;

  return (
    <React.Fragment>
      <Helmet title={title} />
      <Typography variant="h3" gutterBottom display="inline">
        {title}
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                {title}
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  {filteredData ? (
                    <DataAdminTable
                      pageSize={10}
                      isLoading={isFetching}
                      label={title}
                      columns={editTableColumns}
                      data={filteredData}
                      height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 204px)"
                      updateHandler={setFilteredData}
                      endpoint={endpoint}
                      ndxField={ndxField}
                      handleRefresh={refetch}
                      isDeletable={DisableDeleteArray}
                    />
                  ) : (
                    <Loader />
                  )}
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Contacts;

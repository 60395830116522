import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import { useSubmitData } from "../hooks/useSubmitData";
import { getButtonIcon, getButtonText } from "../utils";
import Loader from "../../../../components/Loader";
import { BOOLEAN_OPTIONS } from "./WaterbodiesConstants";
import { useWaterbodyInfo } from "../hooks/useWaterbodyInfo";

function WaterbodyInfo({
  selectedWaterbody,
  setSelectedWaterbody,
  refetchWaterbodies = () => {},
  isEditMode = false,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
  } = useWaterbodyInfo(selectedWaterbody);

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedWaterbody,
    setSelectedWaterbody,
    state,
    "data-list-waterbodies",
    "waterbody_ndx",
    refetch,
    refetchWaterbodies
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.waterbody_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.waterbody_ndx)
              ? "none"
              : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Waterbody Information</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  error={!state.waterbody_name}
                  variant="outlined"
                  label="Waterbody Name"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleUpdateState("waterbody_name", e.target.value)
                  }
                  value={state.waterbody_name ?? ""}
                />
                {!state.waterbody_name && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  error={!state.display_order && state.display_order !== 0}
                  required
                  type="number"
                  variant="outlined"
                  label="Display Order"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleUpdateState("display_order", +e.target.value);
                  }}
                  value={state.display_order ?? ""}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      onKeyPress: (e) => {
                        if (["e", ".", "-"].includes(e.key)) {
                          e.preventDefault();
                        }
                      },
                    },
                  }}
                />
                {!state.display_order && state.display_order !== 0 && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="enabled">Include?</InputLabel>
                <Select
                  labelId="include-label"
                  id="include"
                  label="Include?"
                  value={state?.include ?? ""}
                  onChange={(e) => handleUpdateState("include", e.target.value)}
                >
                  {BOOLEAN_OPTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                label="Notes"
                style={{ width: "100%" }}
                onChange={(e) => handleUpdateState("notes", e.target.value)}
                value={state.notes ?? ""}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={handleResetState}>
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isDirty || !state.waterbody_name || isFormSubmitting}
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting, selectedWaterbody === 0)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default WaterbodyInfo;

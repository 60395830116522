import React from "react";
import SystemGraphs from "./SystemGraphs";

const ScadaStations = () => {
  const defaultParameter = 67;

  const pageTitle = "SCADA Stations";

  const endpoints = [
    { label: "15 Minutes", value: "display001-data15-min" },
    { label: "Daily", value: "display001-data-daily" },
  ];

  const parametersEndpoint = "display001-lookup-parameter";

  const mapEndpoint = "display001-map-points";

  return (
    <>
      <SystemGraphs
        defaultParameter={defaultParameter}
        pageTitle={pageTitle}
        endpoints={endpoints}
        parametersEndpoint={parametersEndpoint}
        mapEndpoint={mapEndpoint}
      />
    </>
  );
};

export default ScadaStations;

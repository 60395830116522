import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Paper } from "@material-ui/core";
import "./styles.css";

import Map from "./map";
import ZoomInfo from "./controls/zoomInfo";
import { useMap } from "./hooks/useMap";
import useFilters from "./hooks/useFilters";

import { INIT_MAP_CONFIG } from "./constants";

import DisclaimerDialog from "./components/DisclaimerDialog";
import MeasurementsPopup from "../../components/map/components/MeasurementsPopup";
import MainControl from "./controls/mainControl/";

import PrintReportDialog from "./components/PrintReportDialog";
import { useReactToPrint } from "react-to-print";
import PrintMapFormat from "./components/PrintMapFormat";
import SplitButton from "../../components/SplitButton";
import MeasurementsControl from "./controls/MeasurementsControl";

import FiltersBar from "./components/FiltersBar";
import Search from "./filters/search";
import DataVizControl from "./controls/dataVizControl";
import DataViz from "./components/DataViz";
import "mapbox-gl-controls/lib/controls.css";

const FiltersBarRoot = styled(Paper)`
  height: 78px;
  align-items: center;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(6)}px;
  padding: 12px 16px 12px 32px;
  overflow-y: scroll;
`;

const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex: 1 1 0;
`;

const FiltersSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex-grow: 100;
`;

const PublicMap = () => {
  const mapContainer = useRef(null);
  const {
    activeBasemap,
    basemaps,
    layers,
    map,
    zoomLevel,
    searchRadiusBuffers,
    resetSearchRadiusBuffers,
    handleClearSearchRadiusBuffers,
    handleEnableSearchRadiusControl,
    updateSearchRadiusBuffers,
    updateLayerFilters,
    updateLayerStyles,
    updateLayerVisibility,
    updateLayerOpacity,
    updateBasemap,
    measurementsVisible,
    handleClearMeasurements,
    setMeasurementsVisible,
    polygonRef,
    radiusRef,
    pointRef,
    lineRef,
    measurementsContainerRef,
    eventsRegistered,
    isMapLoaded,
    dataVizVisible,
    setDataVizVisible,
    dataVizWellNumber,
    dataVizGraphType,
  } = useMap(mapContainer, INIT_MAP_CONFIG);
  const {
    filterValues,
    handleFilterValues,
    handleSelectAll,
    handleSelectNone,
  } = useFilters({ onFilterChange: updateLayerFilters, isMapLoaded });

  const printRef = useRef();
  const [printReportDialogOpen, setPrintReportDialogOpen] = useState(false);
  const [title, setTitle] = useState("");
  const handlePrintMapClick = useReactToPrint({
    content: () => printRef.current,
  });

  const handleSavePNG = () => {
    const a = document.createElement("a");
    a.href = map.getCanvas().toDataURL();
    a.download = "map.png";
    document.body.appendChild(a);
    a.click();
  };

  const splitButtonOptions = ["Print PDF", "Save PNG"];
  const handleSplitButtonClick = (index) => {
    if (index === 0) {
      setPrintReportDialogOpen(true);
    } else if (index === 1) {
      handleSavePNG();
    }
  };

  const handleSearchSelect = (result) => {
    map?.flyTo({ center: result?.location_geometry?.coordinates, zoom: 16 });
  };

  return (
    <>
      {process.env.NODE_ENV !== "development" && <DisclaimerDialog />}

      {process.env.NODE_ENV === "development" && (
        <ZoomInfo zoomLevel={zoomLevel} />
      )}

      <FiltersBarRoot>
        <FiltersContainer>
          <FiltersSectionRow>
            <Search onSelect={handleSearchSelect} />
          </FiltersSectionRow>
        </FiltersContainer>
        <FiltersBar
          filterValues={filterValues}
          handleFilterValues={handleFilterValues}
          handleSelectAll={handleSelectAll}
          handleSelectNone={handleSelectNone}
          updateLayerStyles={updateLayerStyles}
          isMapLoaded={isMapLoaded}
        />

        <FiltersSection>
          <FiltersContainer>
            <>
              <SplitButton
                options={splitButtonOptions}
                handleExportClick={handleSplitButtonClick}
              />

              <PrintReportDialog
                downloadCallback={handlePrintMapClick}
                setPrintReportDialogOpen={setPrintReportDialogOpen}
                printReportDialogOpen={printReportDialogOpen}
                title={title}
                setTitle={setTitle}
              />
            </>
          </FiltersContainer>
        </FiltersSection>
      </FiltersBarRoot>
      <Map ref={mapContainer}>
        <MeasurementsPopup
          measurementsContainerRef={measurementsContainerRef}
          radiusRef={radiusRef}
          polygonRef={polygonRef}
          pointRef={pointRef}
          lineRef={lineRef}
          onHide={() => setMeasurementsVisible(false)}
          onClear={handleClearMeasurements}
        />
        {eventsRegistered && (
          <MainControl
            activeBasemap={activeBasemap}
            basemaps={basemaps}
            bufferValues={searchRadiusBuffers}
            layers={layers}
            onBasemapChange={updateBasemap}
            onBufferValuesChange={updateSearchRadiusBuffers}
            onClearBuffers={handleClearSearchRadiusBuffers}
            onEnableSearchRadiusControl={handleEnableSearchRadiusControl}
            onLayerChange={updateLayerVisibility}
            onOpacityChange={updateLayerOpacity}
            onResetBuffers={resetSearchRadiusBuffers}
          />
        )}

        <DataVizControl
          open={dataVizVisible}
          onClose={() => setDataVizVisible(!dataVizVisible)}
        />

        <DataViz
          open={dataVizVisible}
          dataVizWellNumber={dataVizWellNumber}
          dataVizGraphType={dataVizGraphType}
          onClose={() => setDataVizVisible(false)}
        />

        {!measurementsVisible && (
          <MeasurementsControl
            open={measurementsVisible}
            onToggle={() => setMeasurementsVisible(!measurementsVisible)}
            right={49}
            bottom={30}
          />
        )}
      </Map>

      {eventsRegistered && printReportDialogOpen && (
        <span
          style={{
            display: "none",
            width: "100%",
          }}
        >
          <PrintMapFormat
            ref={printRef}
            title={title}
            mapImg={map.getCanvas().toDataURL("image/png")}
            map={map}
          />
        </span>
      )}
    </>
  );
};

export default PublicMap;

import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Paper,
  Typography,
  withWidth,
  isWidthDown,
} from "@material-ui/core";
import LayersIcon from "@material-ui/icons/Layers";
import MapIcon from "@material-ui/icons/Map";
import SearchRadiusIcon from "@material-ui/icons/WifiTethering";
import ExpandMore from "@material-ui/icons/ExpandMore";
import styled from "styled-components/macro";
import LayersControl from "../layersControl";
import BasemapsControl from "../basemapsControl";
import SearchRadiusControl from "../searchRadiusControl";
import { isWidthUp } from "@material-ui/core/withWidth";

const Container = styled(Paper)`
  background: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  left: 49px;
  position: absolute;
  top: 10px;
  max-height: calc(100% - 32px);
  overflow-x: hidden;
  overflow-y: scroll;
  width: ${({ width }) => width};
  z-index: 3;
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  background-color: #fafafa;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  max-height: ${({ maxheight }) => maxheight};
  // height: ${({ maxheight }) => maxheight};
  overflow-x: hidden;
  overflow-y: auto;
`;

const MainControl = ({
  activeBasemap,
  basemaps,
  bufferValues,
  layers,
  onBasemapChange,
  onBufferValuesChange,
  onClearBuffers,
  onLayerChange,
  onOpacityChange,
  onResetBuffers,
  onEnableSearchRadiusControl,
  width,
}) => {
  const [expandedItem, setExpandedItem] = useState(
    isWidthDown("sm", width) ? false : "layers"
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedItem(isExpanded ? panel : false);

    if (panel === "search-radius") {
      onEnableSearchRadiusControl(isExpanded);
    } else {
      onEnableSearchRadiusControl(false);
    }
  };

  return (
    <Container
      width={
        isWidthDown("sm", width) && !expandedItem
          ? "75px"
          : isWidthDown("sm", width) && expandedItem
          ? "225px"
          : "315px"
      }
    >
      <Accordion
        expanded={expandedItem === "basemaps"}
        onChange={handleChange("basemaps")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box alignItems="center" display="flex" gridColumnGap={8}>
            <MapIcon />
            {(isWidthUp("md", width) || expandedItem) && (
              <Typography variant="subtitle1">Basemaps</Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          maxheight={isWidthDown("xs", width) ? "258px" : "384px"}
        >
          <BasemapsControl
            items={basemaps}
            value={activeBasemap}
            onBasemapChange={onBasemapChange}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedItem === "layers"}
        onChange={handleChange("layers")}
      >
        <AccordionSummary
          aria-controls="layers-content"
          id="layers-header"
          expandIcon={<ExpandMore />}
        >
          <Box alignItems="center" display="flex" gridColumnGap={8}>
            <LayersIcon />
            {(isWidthUp("md", width) || expandedItem) && (
              <Typography variant="subtitle1">Layers</Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          maxheight={isWidthDown("xs", width) ? "258px" : "384px"}
        >
          <LayersControl
            items={layers}
            onLayerChange={onLayerChange}
            onOpacityChange={onOpacityChange}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedItem === "search-radius"}
        onChange={handleChange("search-radius")}
      >
        <AccordionSummary
          aria-controls="search-radius-content"
          id="search-radius-header"
          expandIcon={<ExpandMore />}
        >
          <Box alignItems="center" display="flex" gridColumnGap={8}>
            <SearchRadiusIcon />
            {(isWidthUp("md", width) || expandedItem) && (
              <Typography variant="subtitle1">Radius Tool</Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          maxheight={isWidthDown("xs", width) ? "258px" : "384px"}
        >
          <SearchRadiusControl
            bufferValues={bufferValues}
            onBufferValuesChange={onBufferValuesChange}
            onClearBuffers={onClearBuffers}
            onResetBuffers={onResetBuffers}
          />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default withWidth()(MainControl);

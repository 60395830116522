import React from "react";
import SystemGraphs from "./SystemGraphs";
import CurrentConditions from "./CurrentConditions";

const GateStations = () => {
  const defaultParameter = 1768;

  const pageTitle = "Gate Stations";

  const endpoints = [
    { label: "15 Minutes", value: "display010-data15-min" },
    { label: "Daily", value: "display010-data-daily" },
  ];

  const parametersEndpoint = "display010-lookup-parameter";

  const mapEndpoint = "display010-map-points";

  const currentConditionsEndpoint = "display010-data-lastreport";

  return (
    <>
      <SystemGraphs
        defaultParameter={defaultParameter}
        pageTitle={pageTitle}
        endpoints={endpoints}
        parametersEndpoint={parametersEndpoint}
        mapEndpoint={mapEndpoint}
      >
        <CurrentConditions
          currentConditionsEndpoint={currentConditionsEndpoint}
        />
      </SystemGraphs>
    </>
  );
};

export default GateStations;

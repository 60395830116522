import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  lighten,
  Accordion,
  AccordionDetails,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { customHighlight } from "../../../theme/variants";
import { dateFormatter, standardizeColor } from "../../../utils";
import { useQuery } from "react-query";
import axios from "axios";
import DashBarChart from "../../dataAccess/timeSeries/DashBarChart";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Panel from "../../../components/panels/Panel";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import useDebounce from "../../../hooks/useDebounce";
import { Loader } from "react-feather";
import Table from "../../../components/Table";

const Typography = styled(MuiTypography)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Hero = styled.div`
  background: url("/static/img/riverside-hero.jpg") center center / cover
    no-repeat;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Recap = styled.section`
  border-top: 5px solid ${() => customHighlight[500]};
  margin-bottom: 24px;
  margin-top: 24px;
`;

const Description = styled.div`
  background-color: ${({ color = customHighlight[500] }) => color};
  color: white;
  padding: 30px 60px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Welcome = styled.div`
  height: 250px;
  background-color: rgba(47, 44, 116, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 26px;
  border-radius: 50%;
  position: absolute;
`;

const AboutLink = styled(Link)`
  color: ${() => customHighlight[500]};
  font-weight: 900;
`;

const TimeseriesContainer = styled.div`
  height: 600px;
  width: 100%;
`;

const GraphWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100% - 35px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100% - 35px);
  width: 100%;
`;

function Default() {
  const { user } = useAuth0();
  const saveRef = useRef(null);

  const [filterValues, setFilterValues] = useState({
    endpoint: "display-dashgraph-data-hourly",
    parameter: 67,
    hours: 0,
    hoursInputValue: 0,
  });

  const { data: parameters } = useQuery(
    ["Parameters"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-dashboard-parameters`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleFilterValues = (name, value) => {
    setFilterValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleHoursInput = (event) => {
    const { value } = event.target;
    handleFilterValues("hoursInputValue", +value);
  };

  const debouncedSearchValue = useDebounce(filterValues.hoursInputValue, 1000);

  useEffect(() => {
    if (filterValues.hoursInputValue < 25)
      handleFilterValues("hours", debouncedSearchValue);
  }, [debouncedSearchValue]); //eslint-disable-line

  const { data, error, isFetching } = useQuery(
    [
      `${process.env.REACT_APP_ENDPOINT}/api/${filterValues.endpoint}/${filterValues.parameter}/${filterValues.hours}`,
    ],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${filterValues.endpoint}/${filterValues.parameter}/${filterValues.hours}`
        );

        return data.sort((v1, v2) => {
          return v1.location_label < v2.location_label
            ? -1
            : v1.location_label > v2.location_label
            ? 1
            : 0;
        });
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: true,
      cacheTime: 0,
    }
  );

  const tableColumns = [
    { title: "Location", field: "location_label" },
    { title: "Parameter", field: "parameter_name" },
    { title: "Units", field: "unit_name" },
    {
      title: "Timestamp",
      field: "collect_timestamp",
      render: (rowData) => {
        return dateFormatter(rowData.collect_timestamp, "MM/DD/YYYY, h:mm A");
      },
    },

    { title: "Value", field: "data_value" },
    { title: "Source", field: "datasrc_name" },
  ];

  const [graphData, setGraphData] = useState({});
  useEffect(() => {
    // data?.leftAxis?.length && data?.rightAxis?.length
    if (data?.length) {
      const graphData = {
        labels: data.map((item) => item.location_label),
        units: data[0].unit_name,
        datasets: [
          {
            location: data.map((item) => item.location_label),
            parameter: data[0].parameter_name,
            timestamp: data.map((item) => item.collect_timestamp),
            source: data.map((item) => item.datasrc_name),
            data: data.map((item) => item.data_value),
            yAxisID: "y",
            units: data[0].unit_name,
            fill: true,
            // borderColor: filterData.map((item, i) => lineColors[i]),
            borderColor: data.map((item) => item.bar_color),
            backgroundColor: data.map((item) =>
              lighten(standardizeColor(item.bar_color), 0.8)
            ),
            // borderRadius: 5,
            borderWidth: 1,
            // barThickness: "flex",
            maxBarThickness: 100,
          },
        ],
      };
      setGraphData(graphData);
    }
  }, [data]);

  if (error) return "An error has occurred: " + error.message;

  return (
    <React.Fragment>
      <Helmet title="Riverside Irrigation District Dashboard" />
      <Hero>
        <Welcome>
          <Typography variant="h3" gutterBottom style={{ color: "azure" }}>
            Welcome to the
          </Typography>
          <Typography variant="h2" gutterBottom style={{ color: "azure" }}>
            Riverside Irrigation District Dashboard
          </Typography>

          <Typography variant="subtitle1" style={{ color: "azure" }}>
            {user?.name}
          </Typography>
        </Welcome>
      </Hero>

      <Grid mt={4} container>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="time-series"
              id="time-series"
            >
              {parameters && (
                <Typography variant="h4" ml={2}>
                  {`${
                    parameters.find(
                      (parameter) =>
                        parameter.parameter_ndx === filterValues.parameter
                    ).parameter_name
                  } - ${
                    filterValues.hours === 0
                      ? `Last Report`
                      : filterValues.hours === 1
                      ? `${filterValues.hours} Hour Ago`
                      : `${filterValues.hours} Hours Ago`
                  }`}
                </Typography>
              )}
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TimeseriesContainer>
                  <Box
                    pb={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    style={{ width: "100%" }}
                  >
                    <Typography variant="body1" mr={2} component="span">
                      Look back # of hours (max 24h):
                    </Typography>
                    <TextField
                      style={{ width: "80px" }}
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: 24,
                          onKeyPress: (e) => {
                            if (e.key === ".") {
                              e.preventDefault();
                            }
                          },
                        },
                      }}
                      autoComplete="off"
                      variant="outlined"
                      margin="dense"
                      value={filterValues.hoursInputValue}
                      onChange={handleHoursInput}
                    />
                    {filterValues.hoursInputValue > 24 && (
                      <Typography
                        variant="body2"
                        color="error"
                        ml={2}
                        component="span"
                      >
                        Value must not exceed 24
                      </Typography>
                    )}

                    <span
                      style={{
                        marginLeft: "auto",
                      }}
                    >
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Parameter
                        </FormLabel>
                        <RadioGroup
                          row
                          value={filterValues.parameter}
                          onChange={(e) =>
                            handleFilterValues("parameter", +e.target.value)
                          }
                        >
                          {parameters &&
                            parameters.map((parameter) => (
                              <FormControlLabel
                                key={parameter.parameter_ndx}
                                value={parameter.parameter_ndx}
                                control={<Radio />}
                                label={parameter.parameter_name}
                              />
                            ))}
                        </RadioGroup>
                      </FormControl>
                      {parameters && (
                        <SaveGraphButton
                          ref={saveRef}
                          title={`${
                            parameters.find(
                              (parameter) =>
                                parameter.parameter_ndx ===
                                filterValues.parameter
                            ).parameter_name
                          } - ${
                            filterValues.hours === 0
                              ? `Last Report`
                              : filterValues.hours === 1
                              ? `Hour Ago`
                              : `Hours Ago`
                          }`}
                          disabled={isFetching || !data || !graphData}
                        />
                      )}
                    </span>
                  </Box>

                  <GraphWrapper>
                    {data?.length > 0 && Object.keys(graphData).length > 0 ? (
                      <DashBarChart
                        ref={saveRef}
                        data={graphData}
                        filterValues={filterValues}
                        isLoading={isFetching}
                      />
                    ) : (
                      <Loader />
                    )}
                  </GraphWrapper>
                </TimeseriesContainer>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      <Grid mt={4} container>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Table
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  {parameters && (
                    <Table
                      isLoading={isFetching && !parameters}
                      label={`${
                        parameters.find(
                          (parameter) =>
                            parameter.parameter_ndx === filterValues.parameter
                        ).parameter_name
                      } - ${
                        filterValues.hours === 0
                          ? `${dateFormatter(
                              new Date(),
                              "MM/DD/YYYY, h:mm A"
                            )} - Last Report`
                          : filterValues.hours === 1
                          ? `${dateFormatter(
                              new Date(),
                              "MM/DD/YYYY, h:mm A"
                            )} - ${filterValues.hours} Hour Ago`
                          : `${dateFormatter(
                              new Date(),
                              "MM/DD/YYYY, h:mm A"
                            )} - ${filterValues.hours} Hours Ago`
                      }`}
                      columns={tableColumns}
                      data={data}
                      height="590px"
                    />
                  )}
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      <Recap>
        <Grid container>
          <Grid item xs={12} lg={4} style={{ backgroundColor: "white" }}>
            <Link
              href="https://rivid.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={"/static/img/riverside-logo-large-square.jpg"}
                style={{
                  margin: "auto",
                  display: "block",
                  maxWidth: "100%",
                  // objectFit: "cover",
                  objectFit: "contain",
                  height: "100%",
                }}
                alt="Water Waves"
              />
            </Link>
          </Grid>

          <Grid item xs={12} lg={8}>
            <Description color="#424242">
              <Typography
                variant="h3"
                style={{ fontWeight: "100", fontSize: "30px" }}
              >
                Enjoy the Dashboard!
              </Typography>
              <Typography
                variant="body2"
                component="p"
                style={{
                  padding: "25px",
                  lineHeight: "1.8",
                  fontSize: "1.15rem",
                }}
              >
                This tool provides access to a range of monitoring points
                relevant Riverside Irrigation District. Explore monitoring
                locations for public and internal data sources using the{" "}
                <AboutLink component={NavLink} to="/data-access/map-explorer">
                  Map Explorer
                </AboutLink>{" "}
                or dig deeper into graphical visualizations on the{" "}
                <AboutLink
                  component={NavLink}
                  to="/data-access/time-series-comparisons"
                >
                  Time Series Comparisons
                </AboutLink>{" "}
                page.
              </Typography>
            </Description>
          </Grid>
        </Grid>
      </Recap>
    </React.Fragment>
  );
}

export default Default;

import React from "react";
import SystemGraphs from "./SystemGraphs";

const RiversideOps = () => {
  const defaultParameter = 67;

  const pageTitle = "Riverside Operations";

  const endpoints = [
    { label: "15 Minutes", value: "display004-data15-min-ax1" },
    { label: "Daily", value: "display004-data-daily-ax1" },
  ];

  const parametersEndpoint = "display004-lookup-parameter";

  const mapEndpoint = "display004-map-points";

  return (
    <>
      <SystemGraphs
        defaultParameter={defaultParameter}
        pageTitle={pageTitle}
        endpoints={endpoints}
        parametersEndpoint={parametersEndpoint}
        mapEndpoint={mapEndpoint}
        rightAxis={true}
      />
    </>
  );
};

export default RiversideOps;
